
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-xhr-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from "./Utils/Languages/en.json";
import tr from "./Utils/Languages/tr.json";
import ar from "./Utils/Languages/ar.json";
import fr from "./Utils/Languages/fr.json";
import hi from "./Utils/Languages/hi.json";

const fallbackLng = ['en'];
const availableLanguages = ['en', 'ar', 'fr', "tr", "hi"];
i18n
    .use(Backend)
    .use(LanguageDetector) // detect user language
    .use(initReactI18next) // pass the i18n instance to react-i18next.
    .init({
        resources: {
            en: {
                translation: en
            },
            tr: {
                translation: tr
            },
            fr: {
                translation: fr
            },
            hi: {
                translation: hi
            },
            ar: {
                translation: ar
            }
        },
        fallbackLng, // fallback language is english.

        detection: {
            checkWhitelist: true, // options for language detection
        },

        debug: false,

        whitelist: availableLanguages,

        interpolation: {
            escapeValue: false, // no need for react. it escapes by default
        },
    });

export default i18n;